import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuthenticationStatus, useNhostClient } from '@nhost/nextjs';
import Head from 'next/head';
import Image from 'next/image';
import MetaInfo from '../meta-info/meta-info.component';
import { CLSecondaryCustomButton } from '../ui-controls';

import SignUpComponent from './sign-up.component';
import SignInComponent from './sign-in.component';

declare global {
  interface Window {
    onloadTurnstileCallback: any;
    turnstile: any;
  }
}

interface LoginProps {
  redirectTo?: string;
}

export default function LoginComponent({ redirectTo, ...props }: LoginProps) {
  // Variables
  const router = useRouter();
  const pathName = router.pathname;

  // Hooks
  const nhost = useNhostClient();
  const { isAuthenticated } = useAuthenticationStatus();

  useEffect(() => {
    if (isAuthenticated) {
      router.push('/projects');
    }
  }, [isAuthenticated, router]);

  function socialAuth(provider: string) {
    if (provider === 'google') {
      nhost.auth.signIn({
        provider: 'google',
        options: {
          redirectTo: redirectTo || '/projects'
        }
      });
    } else if (provider === 'microsoft') {
      nhost.auth.signIn({
        provider: 'windowslive',
        options: {
          redirectTo: redirectTo || '/projects'
        }
      });
    }
  }

  const metaInfo = {
    description:
      'checklist.gg is an AI-driven checklists, processes and SOPs management tool designed to help organizations streamline and optimize their workflow. It allows users to create, assign, and track tasks and checklists in real-time, ensuring that all team members are on the same page and that tasks are completed efficiently and effectively.',
    keywords:
      'checklist software, SOP software, checklist management, SOP management, process management, AI checklist, Checklist manifesto',
    metaTitle: 'An AI-driven tool for checklists, processes, and SOPs',
    url: 'https://app.checklist.gg/projects/',
    image:
      `https://app.checklist.gg/api/og?title=` +
      'An AI-driven tool for checklists, processes, and SOPs'
  };

  return (
    <>
      <Head>
        <title>Login</title>
        <MetaInfo metaInfo={metaInfo}></MetaInfo>

        <script
          src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback"
          async
          defer
        ></script>
      </Head>

      <div className="h-full flex flex-col items-center justify-center px-4 py-7 bg-white">
        {/* Login */}
        <div className="m-auto w-[90%] md:w-4/5 max-w-[430px]">
          {/* Logo */}
          <Image
            height="38"
            width="160"
            className="w-40"
            src="/images/logo/checklist.svg"
            alt="checklist.gg"
          />

          {/* Title */}
          <h1 className="text-3xl sm:text-4xl font-bold mt-8">
            {pathName === '/login' ? 'Sign in' : 'Sign up'}
          </h1>

          {/* Description */}
          {/* <p
            className={
              'text-base font-normal mt-3 cl_secondary_color opacity-40'
            }
          >
            Enter your email to get things done right every time
          </p> */}

          {/* Social auth */}
          <div className="my-8 space-y-2">
            {/* Google */}
            <CLSecondaryCustomButton
              className="w-full py-3 gap-2"
              onClick={() => {
                socialAuth('google');
              }}
            >
              <svg
                width="20"
                height="20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M19.99 10.187c0-.82-.069-1.417-.216-2.037H10.2v3.698h5.62c-.113.92-.725 2.303-2.084 3.233l-.02.124 3.028 2.292.21.02c1.926-1.738 3.037-4.296 3.037-7.33z"
                    fill="#4285F4"
                  />
                  <path
                    d="M10.2 19.931c2.753 0 5.064-.886 6.753-2.414l-3.218-2.436c-.862.587-2.017.997-3.536.997a6.126 6.126 0 01-5.801-4.141l-.12.01-3.148 2.38-.041.112c1.677 3.255 5.122 5.492 9.11 5.492z"
                    fill="#34A853"
                  />
                  <path
                    d="M4.398 11.937a6.009 6.009 0 01-.34-1.971c0-.687.125-1.351.329-1.971l-.006-.132-3.187-2.42-.105.05A9.79 9.79 0 00.001 9.965a9.79 9.79 0 001.088 4.473l3.309-2.502z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M10.2 3.853c1.914 0 3.206.809 3.943 1.484l2.878-2.746C15.253.985 12.953 0 10.199 0 6.211 0 2.766 2.237 1.09 5.492l3.297 2.503A6.152 6.152 0 0110.2 3.853z"
                    fill="#EB4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
              Sign-in with Google
            </CLSecondaryCustomButton>

            {/* Microsoft */}
            <CLSecondaryCustomButton
              className="w-full py-3 gap-2"
              onClick={() => {
                socialAuth('microsoft');
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23 23"
                width="20"
                height="20"
              >
                <path fill="#f35325" d="M1 1h10v10H1z" />
                <path fill="#81bc06" d="M12 1h10v10H12z" />
                <path fill="#05a6f0" d="M1 12h10v10H1z" />
                <path fill="#ffba08" d="M12 12h10v10H12z" />
              </svg>
              Sign-in with Microsoft
            </CLSecondaryCustomButton>
          </div>

          {/* Or */}
          <div className="flex items-center justify-center">
            <hr className="w-full" />
            <p className="text-xs px-2">OR</p>
            <hr className="w-full" />
          </div>

          {/* Magic link */}
          {/* <MagicLinkComponent redirectTo={redirectTo} /> */}

          {/* Sign-in page */}
          {pathName === '/login' && <SignInComponent redirectTo={redirectTo} />}

          {/* Sign-up page */}
          {(pathName === '/sign-up' || pathName.startsWith('/invite/')) && (
            <SignUpComponent redirectTo={redirectTo} />
          )}
        </div>
      </div>
    </>
  );
}
