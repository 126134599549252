import { KeyboardEvent, useEffect, useState } from 'react';
import { CLPrimaryCustomButton, CLPrimaryInput } from '../ui-controls';

import { isValidEmail } from '@/services/utils/utils.service';
import { useSignInEmailPassword } from '@nhost/nextjs';
import { LoadingSpinWhiteCustom } from '../utils/loading.component';

interface SignInProps {
  redirectTo?: string;
}

export default function SignInComponent({ redirectTo }: SignInProps) {
  // Variables

  // States
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [isEmailLoading, setEmailLoading] = useState<boolean>(false);

  // Hooks
  const {
    signInEmailPassword,
    needsEmailVerification,
    isLoading,
    isSuccess,
    isError,
    error
  } = useSignInEmailPassword();

  useEffect(() => {
    // Set error message
    if (isError) {
      if (error?.message) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg('Something went wrong');
      }
    } else {
      setErrorMsg('');
    }

    // Info message
    if (needsEmailVerification) {
      setSuccessMsg(
        'Please check your inbox and verify your email to proceed.'
      );
    } else {
      setSuccessMsg('');
    }
  }, [needsEmailVerification, isLoading, isSuccess, isError, error]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      signIn();
    }
  };

  async function signIn() {
    // Reset
    setErrorMsg('');
    setSuccessMsg('');

    // Validation
    if (!email) {
      setErrorMsg('Please enter your email address');
      document.getElementById('sign_in_email_field')?.focus();
      return;
    } else if (!isValidEmail(email)) {
      setErrorMsg('Please enter a valid email address');
      document.getElementById('sign_in_email_field')?.focus();
      return;
    } else if (!password) {
      setErrorMsg('Please enter your password');
      document.getElementById('sign_in_password_field')?.focus();
      return;
    }

    // SignUp
    await signInEmailPassword(email, password);
  }

  return (
    <div className="mt-8">
      <div className="space-y-2">
        <div>
          <CLPrimaryInput
            id="sign_in_email_field"
            type="email"
            label="Email *"
            value={email}
            onKeyDown={handleKeyDown}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div>
          <CLPrimaryInput
            id="sign_in_password_field"
            type="password"
            label="Password *"
            value={password}
            onKeyDown={handleKeyDown}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <div className="flex justify-end">
          <a href="forget-password">Forgot password?</a>
        </div>
      </div>

      {/* Turnstile container */}
      <div id="cl-turnstile-container"></div>

      {/* Error message */}
      {errorMsg && <div className="mt-2 text-red-400">{errorMsg}</div>}

      {/* Success message */}
      {successMsg && <div className="mt-2 text-black">{successMsg}</div>}

      <div className="mt-4 w-full">
        <CLPrimaryCustomButton
          className="w-full text-base px-3 py-3"
          onClick={() => {
            signIn();
          }}
          disabled={isEmailLoading || isLoading}
        >
          {(isEmailLoading || isLoading) && (
            <LoadingSpinWhiteCustom className="w-5 h-5"></LoadingSpinWhiteCustom>
          )}
          Sign in
        </CLPrimaryCustomButton>
      </div>

      {/* Go to signUp screen */}
      <div className="flex items-center justify-center mt-4">
        {/* Already have an account? */}
        <span className="text-black/[0.7]">Don&apos;t have an account?</span>
        {/* Add extra space here */}
        <span className="mx-1"></span>
        <a href="sign-up">Create an account.</a>
      </div>
    </div>
  );
}
