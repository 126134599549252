import { KeyboardEvent, useEffect, useState } from 'react';
import { CLPrimaryCustomButton, CLPrimaryInput } from '../ui-controls';

import axios, { AxiosError } from 'axios';

import CommonConstants from '@/constants/common-constants';
import { isValidEmail } from '@/services/utils/utils.service';
import { LoadingSpinWhiteCustom } from '../utils/loading.component';

declare global {
  interface Window {
    onloadTurnstileCallback: any;
    turnstile: any;
  }
}

interface SignUpProps {
  redirectTo?: string;
}

export default function SignUpComponent({ redirectTo }: SignUpProps) {
  // Variables

  // States
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [isEmailLoading, setEmailLoading] = useState<boolean>(false);
  const [turnstileToken, setTurnstileToken] = useState<string>('');
  const [isVerificationEmailSent, setIsVerificationEmailSent] =
    useState<boolean>(false);

  useEffect(() => {
    window.onloadTurnstileCallback = () => {
      window.turnstile.render('#cl-turnstile-container', {
        sitekey: process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY,
        callback: (token: string) => {
          setTurnstileToken(token);
        }
      });
    };
  }, []);

  function refreshTurnstileToken() {
    if (window.turnstile) {
      window.turnstile.reset('#cl-turnstile-container');
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      signUp();
    }
  };

  async function signUp() {
    let turnstileToken;

    // Reset
    setErrorMsg('');
    setSuccessMsg('');

    // Validation
    if (!email) {
      setErrorMsg('Please enter your email address');
      document.getElementById('sign_up_email_field')?.focus();
      return;
    } else if (!isValidEmail(email)) {
      setErrorMsg('Please enter a valid email address');
      document.getElementById('sign_up_email_field')?.focus();
      return;
    } else if (!password) {
      setErrorMsg('Please enter your password');
      document.getElementById('sign_up_password_field')?.focus();
      return;
    } else if (password && password.length < 8) {
      setErrorMsg('Password must be at least 8 characters');
      document.getElementById('sign_up_password_field')?.focus();
      return;
    }

    // Turnstile validation
    if (!window.turnstile) {
      setErrorMsg('Turnstile is not yet loaded.');
      return;
    } else {
      turnstileToken = window.turnstile.getResponse('#cl-turnstile-container');

      if (!turnstileToken) {
        setErrorMsg(
          'Turnstile token is not found. Please retry in few seconds.'
        );
        return;
      }
    }

    // Start email loading
    setEmailLoading(true);

    // Refresh the token always, since current token will be used in the following request
    refreshTurnstileToken();

    // Send magic-link
    try {
      const response = await axios.post(
        CommonConstants.API_PREFIX + '/login/email-password',
        {
          email,
          password,
          redirectTo:
            'https://' + document.location.host + (redirectTo || '/projects')
        },
        {
          headers: {
            Authorization: 'Bearer ' + turnstileToken
          }
        }
      );
      setSuccessMsg(
        "Please check your inbox and verify your email to proceed. <br /> In case you don't see email in Inbox, please check Spam and mark it Not Spam"
      );
      setIsVerificationEmailSent(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(error?.response?.data?.error?.message || error.message);
      } else {
        setErrorMsg('Something Went wrong. Please try again');
      }
    }

    // Stop email loading
    setEmailLoading(false);
  }

  return (
    <div className="mt-8">
      <div className="space-y-2">
        <div>
          <CLPrimaryInput
            id="sign_up_email_field"
            type="email"
            label="Email *"
            value={email}
            onKeyDown={handleKeyDown}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div>
          <CLPrimaryInput
            id="sign_up_password_field"
            type="password"
            label="Password *"
            value={password}
            onKeyDown={handleKeyDown}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
          />
        </div>
      </div>

      {/* Turnstile container */}
      <div id="cl-turnstile-container"></div>

      {/* Error message */}
      {errorMsg && <div className="mt-2 text-red-400">{errorMsg}</div>}

      {/* Success message */}
      {successMsg && (
        <div
          className="mt-2 text-black"
          dangerouslySetInnerHTML={{ __html: successMsg }}
        ></div>
      )}

      <div className="mt-4 w-full">
        <CLPrimaryCustomButton
          className="w-full text-base px-3 py-3"
          onClick={() => {
            signUp();
          }}
          disabled={
            isEmailLoading || !turnstileToken || isVerificationEmailSent
          }
        >
          {isEmailLoading && (
            <LoadingSpinWhiteCustom className="w-5 h-5"></LoadingSpinWhiteCustom>
          )}
          Create new account
        </CLPrimaryCustomButton>
      </div>

      {/* Go to login screen */}
      <div className="flex items-center justify-center mt-4">
        <span className="text-black/[0.7]">Already have an account?</span>
        {/* Add extra space here */}
        <span className="mx-1"></span>
        <a href="login">Sign in.</a>
      </div>
    </div>
  );
}
